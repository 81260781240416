import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonWrapper,
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhoWillSuiteACT.styles'
import { TitleWrapper } from '../Common.styles'

export const WhoWillSuiteACT = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Кому подойдет терапия принятия и ответственности
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Вы можете выбрать для себя работу в терапии принятия и
                ответственности, независимо от того, есть ли у вас официальный
                диагноз.
              </Text.Large>
              <Text.Large semiBold>
                В работе с ACT-терапевтом вы будете уделять внимание процессам и
                навыкам в контексте нынешней ситуации, которые повышают
                психологическую гибкость и делают вас более устойчивыми, а жизнь
                – более осмысленной.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Кому подойдет терапия принятия и ответственности" />
          </ImageWrapper>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
